export const AppName = "DS Digital"; //
export const drawerWidth = "260px";
export const miniDrawerWidth = "80px";
export const themeSettingDrawerWidth = "300px";
export const chatDrawerWidth = "260px";
export const chatMiniDrawerWidth = "0px";

export const loginBack = require("assets/images/loginback.jpg");
export const iconDemo = require("assets/images/logo_form.png"); //
export const roelogo2 = require("assets/images/logo_white.png");
export const ForgotIcon = require("assets/images/forgotpassword.svg");
export const locakscreenBack = require("assets/images/lockscreen.jpg"); 
export const ProfileLockScreen = require("assets/images/profile.jpg"); //
export const errorback = require("assets/images/errorback.jpg");
export const sidebar1 = require("assets/images/sidebar1.jpg"); //

// File Manager

// export const folder = require("assets/images/filemanager/folder.jpg");