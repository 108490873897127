import { useEffect } from "react";

export default function useBasicViewerConfig(
  loaded,
  potreeLibRef,
  potreeViewerRef
) {
  useEffect(() => {
    if (loaded && potreeViewerRef.current && potreeLibRef.current) {
      const viewer = potreeViewerRef.current;

      viewer.setEDLEnabled(false);
      viewer.setFOV(60);
      viewer.setPointBudget(5_000_000);
      viewer.loadSettingsFromURL();
      viewer.setBackground("None");
      viewer.setDescription("DS - Gêmeo Digital");

      viewer.loadGUI(() => {
        viewer.setLanguage("en");
        //$("#menu_tools").next().show();
        //$("#menu_clipping").next().show();
        viewer.toggleSidebar();
      });

      console.log("using basic Potree viewer config");
    }
  }, [loaded, potreeViewerRef, potreeLibRef]);
}
