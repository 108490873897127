import React from "react";

const FormElements = props => {
    const buttonBack = {
        backgroundColor: '#035184',
        color: 'white'
    };
    return (
        <div>
            <form>
                <div className="form-group row">
                    <label>
                        Descrição
                    </label>
                    <input
                        type="text"
                        className="form-control react-form-input"
                        placeholder="Descrição"
                    />      
                </div>

                <div className="form-group row">
                    <label>Tipo</label>
                    <select
                        className="form-control react-form-input selectpicker"
                        data-style="btn-new"
                    >
                        <option>Aerofotogrametria</option>
                        <option>Gêmeos Digitais</option>
                        <option>Inspeção de Fissuras</option>
                    </select>       
                </div>


                <div className="form-group row theme-color">
                    <label>
                        Carregar KML
                    </label>
                    <input
                        type="file"
                        className="form-control-file"
                        id="exampleFormControlFile1"
                    /> 
                </div>

                <div className="form-group">
                    <button
                        style={buttonBack}
                        type="submit"
                        className="btn form-button"
                    >
                        Enviar
                    </button>
                </div>
                
            </form>
        </div>
    );
};

export default FormElements;
