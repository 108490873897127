import React from "react";
import { loginBack, iconDemo } from "helper/constant";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import enhancer from "./enhancer/RegisterFormEnhancer";
import axios from 'axios'
import Swal from 'sweetalert2';

const Register = props => {
    const handleRegister = async (e) => {
        e.preventDefault();

        let { values, isValid, handleSubmit } = props;

        if (isValid) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/signup`, values);
                if (response.status == 201) {                    
                    Swal.fire({
                        icon: 'success', 
                        title: 'Registro Bem-sucedido!',
                        text: 'Seu registro foi concluído com êxito.',
                      });
                    props.history.push('/login');
                } else {
                    console.error('Erro desconhecido ao registrar usuário:', response.data);
                }
            } catch (error) {
                console.error('Erro ao fazer solicitação de registro:', error);

                if (error.response.status == 409) {
                    console.error('Usuário já cadastrado!');                    
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro!',
                        text: 'Email já cadastrado!',
                      });
                } else {
                    console.error('Erro desconhecido ao registrar usuário:', error.response.data);
                }
            }
        }
    };

    const { handleChange, handleBlur, errors, touched, submitCount } = props;

    const loginContainer = {
        backgroundImage: `url(${loginBack})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "fixed",
        overflow: "auto",
        top: 0,
        bottom: 0
    };

    const Error = props => {
        const field1 = props.field;
        if ((errors[field1] && touched[field1]) || submitCount > 0) {
            return (
                <span className={props.class ? props.class : "error-msg"}>
                    {errors[field1]}
                </span>
            );
        } else {
            return <span />;
        }
    };

    return (
        <div className="container-fluid" style={loginContainer}>
            <div>
                <div className="form-container">
                    <div className="login-icon">
                        <img src={iconDemo} alt="icon" height="100px" />
                    </div>
                    <div className="login-title">Criar Conta</div>
                    <form className="pa-24" onSubmit={handleRegister}>
                        <div className="form-group">
                            <label>Nome</label>
                            <input
                                type="text"
                                className="form-control react-form-input"
                                id="fullName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Seu nome..."
                            />
                            <Error field="fullName" />
                        </div>

                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                className="form-control react-form-input"
                                id="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Seu email..."
                            />
                            <Error field="email" />
                        </div>

                        <div className="form-group">
                            <label>Empresa</label>
                            <input
                                type="text"
                                className="form-control react-form-input"
                                id="company"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Sua empresa..."
                            />
                            <Error field="company" />
                        </div>

                        <div className="form-group">
                            <label>Categoria</label>
                            <select
                                className="form-control react-form-input selectpicker"
                                data-style="btn-new"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="userCategory"
                            >
                                <option>Cliente</option>
                                <option>Piloto</option>
                                <option>Especialista</option>
                                <option>Engenheiro</option>
                            </select>
                            <Error field="userCategory" />
                        </div>

                        <div className="form-group">
                            <label>Senha</label>
                            <input
                                type="password"
                                className="form-control react-form-input"
                                id="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Sua senha..."
                            />
                            <Error field="password" />
                        </div>

                        <div className="form-group">
                            <label>Confirme a Senha</label>
                            <input
                                type="password"
                                className="form-control react-form-input"
                                id="passwordCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Confirme sua senha..."
                            />
                            <Error field="passwordCheck" />
                        </div>

                        <div className="form-check text-center mtb-16">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck1"
                            />
                            <label
                                className="form-check-label register-privacy-text"
                                htmlFor="exampleCheck1"
                            >
                                Concordo com {" "}
                                <a href="/register">Termos & Condições</a>
                            </label>
                        </div>

                        <button type="submit" className="btn form-button">
                            Registrar
                        </button>
                        <div
                            className="text-center link-label"
                            onClick={() => props.history.push("/login")}
                        >
                            Acessar?
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default compose(
    withRouter,
    enhancer
)(Register);
