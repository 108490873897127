//  SOLICITACOES
import { OrderService } from "views/order/index";


// PROJETOS
import { TourVirtual, TourVirtual2, GemeoDigital, Cartografia } from "views/projects/index";

// USER
import { UserProfile } from "views/user/index";


// ADMIN
import { Workflow } from "views/admin/index";



const dashboardRoutes = [

  { path: "/order-service", component: OrderService },

  { path: "/tour", component: TourVirtual },
  { path: "/tour2/:id", component: TourVirtual2 },
  { path: "/gemeo-digital", component: GemeoDigital },
  { path: "/cartografia", component: Cartografia },

  { path: "/perfil", component: UserProfile },

  { path: "/workflow", component: Workflow },

];

export default dashboardRoutes;
