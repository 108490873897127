import React, { useEffect, useRef } from 'react';
import 'aframe';
import L from 'leaflet';
import EXIF from 'exif-js';
import customIconImage from 'assets/images/gps.png';
import customIconActive from 'assets/images/gps_active.png';

function Minimap({ images, onMarkerClick, activeMarker }) {

    const mapRef = useRef(null);

    const customIcon = L.icon({
        iconUrl: customIconImage,
        iconSize: [32, 32],
    });

    const customActive = L.icon({
        iconUrl: customIconActive,
        iconSize: [32, 32],
    });

    useEffect(() => {
        if (!mapRef.current) {
            const map = L.map('minimap').setView([0, 0], 30);
            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { attribution: 'DS' }).addTo(map);
            mapRef.current = map;
        }
        
        images.forEach((imageURL, index) => {
            
            const image = new Image();
            image.src = imageURL['path'];

            image.onload = () => {
                EXIF.getData(image, function () {
                    const latitudeArray = EXIF.getTag(this, 'GPSLatitude');
                    const latitudeRef = EXIF.getTag(this, 'GPSLatitudeRef');
                    const longitudeArray = EXIF.getTag(this, 'GPSLongitude');
                    const longitudeRef = EXIF.getTag(this, 'GPSLongitudeRef');

                    if (latitudeArray && longitudeArray) {

                        const latitude = -1 * (latitudeArray[0] + latitudeArray[1] / 60 + latitudeArray[2] / 3600) * (latitudeRef === 'N' ? -1 : 1);
                        const longitude = -1 * (longitudeArray[0] + longitudeArray[1] / 60 + longitudeArray[2] / 3600) * (longitudeRef === 'E' ? -1 : 1);

                        const marker = L.marker([latitude, longitude], {
                            icon: customIcon,
                            index: index,
                        });

                        marker.addTo(mapRef.current);
                        
                        marker.on('click', () => {
                            onMarkerClick(index);
                            marker.setIcon(customActive);
                        });

                        if (index === activeMarker || (activeMarker === null && index === 0)) {
                            marker.setIcon(customActive);
                        }

                        mapRef.current.setView([latitude, longitude], 18);
                    }
                });
            };
        });
    }, [images, activeMarker]);


  return (
    <div id="minimap" style={{ position: 'absolute', top: '100px', left: '10px', width: '25%', height: '25%' }}></div>
  );
}

export default Minimap;
