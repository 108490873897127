import React, { useState, useEffect } from 'react';
import 'aframe';
import 'components/maps/modal.css';
import EXIF from 'exif-js';
import Minimap from 'components/maps/Minimap';

function TourVirtual() {
  const [images, setImages] = useState([]);
  const [currentScene, setCurrentScene] = useState(null);
  const [activeMarkerIndex, setActiveMarkerIndex] = useState(null);

  useEffect(() => {
    // Função para carregar a lista de imagens
    const loadImages = async () => {
      const imageFiles = await importAll(require.context('../../assets/images/tour', false, /\.(jpg|jpeg|png|gif|bmp|svg|JPG)$/));

      const imageList = await Promise.all(imageFiles.map(async (imagePath) => {
        const imageBase64 = await loadImage(imagePath);
        return { path: imagePath, src: imageBase64 };
      }));

      setImages(imageList);
      setCurrentScene(imageList[0] ? imageList[0].src : null);
    };

    loadImages();
  }, []);

  const loadImage = async (imagePath) => {
    
    try {
      // Use a URL relativa para carregar a imagem diretamente da pasta original
      const image = new Image();
      image.src = `${imagePath}`;

      return new Promise((resolve) => {
        image.onload = () => {
          // Coloque seu código EXIF aqui
          EXIF.getData(image, function () {
          });
          resolve(image.src);
        };
      });
    } catch (error) {
      console.error('Erro ao carregar a imagem:', error);
      return null;
    }
  };

  const handleMarkerClick = (index) => {
    const selectedImage = images[index];
    
    if (selectedImage && selectedImage.src) {
      setCurrentScene(selectedImage.src);
      setActiveMarkerIndex(index);
    }
  };

  useEffect(() => {}, [activeMarkerIndex])


  return (
    <div>
      <form>
        <div className="form-group row">
          <div className="vr-canvas-container">
            <a-scene className={''} embedded width="1200px !important" height="100vh !important">
              {currentScene && (
                <>
                  <a-sky src={currentScene} rotation="0 -90 0"></a-sky>
                  <a-entity camera look-controls wasd-controls="acceleration:1000000000" position="0 1.6 0"></a-entity>
                  <a-light type="ambient" position="0 5 0" intensity="0.5"></a-light>
                  <a-light type="directional" position="-3 3 1" intensity="0.5"></a-light>
                </>
              )}
            </a-scene>
            {/* Adicione o minimapa no canto inferior esquerdo */}
            <Minimap images={images} onMarkerClick={handleMarkerClick} activeMarker={activeMarkerIndex}/>
          </div>
        </div>
      </form>
    </div>
  );
}

export default TourVirtual;

// Função para importar todos os arquivos na pasta
function importAll(r) {
  return r.keys().map(r);
}
