import { randomUUID } from "helper/methods";


export const boardsData = [
    {
        tasks: [
            {
                id: randomUUID(),
                completed: false,
                card_id: 1,
                sequence: "(",
                title: "Aerofotogrametria",
                description: "Toda a planta",
                labels: [
                    {
                        color: "label6",
                        id: 6,
                        name: "No Prazo"
                    }
                ],
                members: [
                    {
                        pics: "",
                        first: "DS",
                        last: "Digital",
                        id: 1
                    }
                ],
                owner: {},
                attachments: [
                ],
                subtasks: [
                ]
            },
            
            {
                id: randomUUID(),
                completed: false,
                card_id: 2,
                sequence: "]",
                title: "Drone MAVIC",
                description: "Captura de imagens",
                labels: [
                    {
                        color: "label1",
                        id: 1,
                        name: "Urgente"
                    }
                ],
                members: [
                    {
                        pics: "",
                        first: "Osmar",
                        last: "- Piloto",
                        id: 5
                    }
                ],
                owner: {},
                subtasks: [],
                attachments: []
            }
        ],
        cards: [
            {
                board_id: 10,
                id: 1,
                sequence: "{",
                title: "Demanda",
                user_id: 263
            },
            {
                board_id: 10,
                id: 2,
                sequence: "|",
                title: "Captura de Imagem",
                user_id: 264
            },
            {
                board_id: 10,
                id: 3,
                sequence: "}",
                title: "Processamento",
                user_id: 265
            },
            {
                board_id: 10,
                id: 4,
                sequence: "~Z",
                title: "Entrega",
                user_id: 266
            }
        ],
        // Facilitate reordering of the columns
        title: "GASLUB",
        id: 1,
        isFav: true
    }
]
