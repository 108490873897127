import React, { useState } from "react";
import { connect } from "react-redux";
import DefaultMap from "components/maps/DefaultMap";
import FormElements from "components/forms/FormElements";

const OrderService = () => {
  const [center] = useState({
    lat: 45.9432,
    lng: 24.9668
  });

  const [zoom] = useState(5);

  return (
    <div className="chart-container">
      <div className="row">
        <div className="col-md-4">
          <div className="roe-card-style">
            <div className="roe-card-header">
              <span className="hash"># </span>Dados do Serviço
            </div>
            <div className="roe-card-body">
              <FormElements />
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="roe-card-style">
            <div className="roe-card-header">
              <span className="hash"># </span> Caso não possua arquivo KML, faça a marcação no mapa abaixo
            </div>
            <div className="roe-card-body">
              <DefaultMap center={center} zoom={zoom} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(OrderService);
