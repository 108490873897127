import React, { useState, useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";

const DefaultMap = (props) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    // Inicializa o mapa Leaflet
    const leafletMap = L.map("map").setView([0, 0], props.zoom); // Inicialize com coordenadas padrão (0, 0)

    // Adiciona uma camada de mapa (por exemplo, OpenStreetMap)
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: 'DS',
    }).addTo(leafletMap);

    // Obtém a localização do usuário (se disponível)
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const userLat = position.coords.latitude;
        const userLng = position.coords.longitude;

        // Define o centro do mapa com base na localização do usuário
        leafletMap.setView([userLat, userLng], props.zoom);
      });
    }

    // Inicializa o recurso de desenho (para polígonos)
    const drawnItems = new L.FeatureGroup();
    leafletMap.addLayer(drawnItems);

    const drawControl = new L.Control.Draw({
      draw: {
        polygon: true,
        marker: false,
        circlemarker: false,
        circle: false,
        polyline: false,
      },
      edit: {
        featureGroup: drawnItems,
      },
    });

    leafletMap.addControl(drawControl);

    // Event listener para quando o usuário terminar de desenhar um polígono
    leafletMap.on(L.Draw.Event.CREATED, (event) => {
      const layer = event.layer;

    // Converte o polígono para um formato KML
    const kml = `<b>Polígono:</b><br>${polygonToKML(layer)}`;

    // Calcula a área do polígono
    const polygonArea = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
    const polygonAreaHectares = polygonArea / 10000;
    const formattedArea = `<b>Área (hectares):</b><br>${polygonAreaHectares.toFixed(2)} ha`;

    // Cria um popup para exibir o KML e a área com espaço entre eles e em negrito
    layer.bindPopup(`${kml}<br><br>${formattedArea}`).openPopup();

    // Adiciona o polígono ao grupo desenhado
    drawnItems.addLayer(layer);
    });

    setMap(leafletMap);

    return () => {
      // Limpa o mapa quando o componente for desmontado
      leafletMap.remove();
    };
  }, [props.center, props.zoom]);

  // Função para converter um polígono em formato KML
  const polygonToKML = (polygon) => {
    const latLngs = polygon.getLatLngs()[0];
    const coordinates = latLngs.map((latLng) => `${latLng.lng},${latLng.lat},0`);
    return coordinates.join(" ");
  };

  return <div id="map" style={{ width: "100%", height: "400px" }}></div>;
};

export default DefaultMap;
