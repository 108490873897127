import React from "react";
import { connect } from "react-redux";
import PotreePointcloud from 'components/vr/PotreePointcloud'

const GemeoDigital = () => {
  
  return (
    <div className="roe-card-style mt-15 mb-30 mlr-15 mobile-spacing-class no-box-container">
        <PotreePointcloud />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(GemeoDigital);
